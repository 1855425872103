<template>
  <div>
    <div class="topitem" v-for="(item,idx) in list" :key="idx">
      <div :class="item.type=='rg'?'rg row-l':'rg row-l float-r gr'">
        <img  v-if="item.type=='rg'" class="rgimg" src="@assets/img/kflogo.png" alt=""/>
        <div  :class="item.type=='rg'?'rgcom':'rgcom text-r'">
          <div :class="item.type=='rg'?'time':'time text-r'">
            <span class="name">{{item.name}}</span>
            <span>{{item.time}}</span>
          </div>
          <div class="hd" v-if="typeof(item.data) == 'object'">
              <div class="clts">{{item.sort==1?'请点击相应的分类':'请点击相应的问题'}}</div>
              <div class="clitem" v-for="(i,dx) in item.data" :key="dx" @click="gettit(item.sort==1?i.typename:i.title,item.sort==1?i.typecode:'')">
                {{item.sort==1?i.typename:i.title}}
              </div>
          </div>
          <div class="hd" v-else v-html="item.data" >
          </div>
          <div class="hd" v-if="item.data.length==0" >
            {{kefu}}
          </div>
        </div>
        <img  v-if="item.type=='gr'" class="rgimg" src="@assets/img/kfuse.png" alt=""/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "answer",
  props:['list','kefu'],
  methods:{
    gettit(val,typecode){
      this.$emit('getit',val,typecode)
    }
  }
}
</script>

<style scoped lang="less">
.topitem{
  overflow: hidden;
  .rg {
    max-width: 420px;
    margin-top: 15px;

    .rgimg {
      width: 25px;
      height: 25px;
      background: #20242b;
      padding: 2px;
      border-radius: 50px;
      margin: 31px 4px 0;
    }

    .time {
      color: #999999;
      line-height: 26px;
      font-size: 13px;

      .name {
        margin-right: 12px;
      }
    }

    .hd {
      background: #f3f3f3;
      padding: 6px 8px;
      border-radius: 4px;
      font-size: 14px;
      color: #333333;
      line-height: 24px;
      display: inline-block;
      text-align: left;
    }

  }

  .gr {
    .rgimg {
      background: #ffeb3b !important;
    }
  }
  .clitem{
    color: @bule;
    cursor: pointer;
    &:hover{
      font-weight: bold;
    }
  }
}

</style>
