<template>
  <div class="service column-cen ">
    <div class="coms">
      <div class="nav">
        <div class="header btw">
          <div class="rightText row">
            <img class="logo" src="/logo.png" alt=""/>
            欢迎咨询易招云采电子招投标交易平台！
          </div>
          <div class="leftText">
            <router-link to="/">返回首页</router-link>
          </div>
        </div>
      </div>
      <div class="com btw">
        <div class="l">
          <div class="top" ref='record'>
            <answer :list="list" :kefu="cominfo.serveice.answer" @getit="getit"></answer>
          </div>
          <div class="ts">
<!--            <div class="row-l">-->
<!--            问题没有解决?点击人工客服QQ:-->
<!--            <span class="linqq row" @click="linkqq">-->
<!--               <img  src="@assets/img/qq.png" alt=""/>-->
<!--              {{cominfo.serveice.qq}}</span>-->
<!--            <span>( {{cominfo.serveice.time}}) </span>-->
<!--            </div>-->
            <div class="row-l">
              问题没有解决?请拨打客服热线:
              <span class="linqq row">
              {{cominfo.serveice.tel}}</span>
              <span>( {{cominfo.serveice.time}}) </span>
            </div>

          </div>
          <div class="bot">
            <el-input class="botinput" type="textarea" placeholder="很高兴为您服务,请描述你的问题" :rows="6" v-model="value"
                      @keydown.native="handleKeyCode($event)"></el-input>
          </div>
          <el-button class="but" type="primary" size="small" @click="getval">发送</el-button>
        </div>
        <div class="r">
          <cjwt ></cjwt>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {getcjwttype, getcjwt} from "@/api/service.js";
import cjwt from './com/cjwt'
import answer from './com/answer'

export default {
  name: "service",
  components: {
    cjwt, answer
  },
  props:['cominfo'],
  data() {
    return {
      value: '',
      list: [],
      qq:'1247225622'
    };
  },
  mounted() {
    this.getcjwttype()
  },
  methods: {
    // 键盘回车事件
    handleKeyCode(event) {
      if (event.keyCode == 13) {
        if (!event.metaKey) {
          event.preventDefault();
          this.getval();
        } else {
          this.messageTxt = this.messageTxt + '\n';
        }
      }
    },// 发送消息
    getval() {
      if (this.value) {
        let param = {
          name: '我',
          time: this.com.formatDate(),
          data: this.value,
          type: 'gr'
        }
        this.list.push(param)
        this.getreply(this.value)
        this.value = ''
      }
    },
    getit(val, typecode) {
      let param = {
        name: '我',
        time: this.com.formatDate(),
        data: val,
        type: 'gr'
      }
      this.list.push(param)
      this.getreply(val, typecode)
    },
    getreply(val, typecode) {
      let prame = {
        pageindex: 1,
        pagesize: 100,
      }

      if (typecode) {
        prame.typecode = typecode
      } else {
        prame.keyword = val
      }
      getcjwt(prame).then((data) => {
        let answer = ''
        if (data.total == 1) {
          answer = typecode ? data.rows : data.rows[0].answer
        } else if (data.total > 1) {
          answer = data.rows
        } else {
          answer = this.cominfo.serveice.answer
        }
        let param = {
          name: '易招云采',
          time: this.com.formatDate(),
          data: answer,
          type: 'rg'
        }
        this.list.push(param)
      });
    },
    getcjwttype() {
      getcjwttype().then((data) => {
        let mrlist = [
          {
            name: '易招云采',
            time: this.com.formatDate(),
            data: this.cominfo.serveice.tips,
            type: 'rg'
          },
          {
            name: '易招云采',
            time: this.com.formatDate(),
            data: data.tdata,
            type: 'rg',
            sort: 1
          }
        ]
        this.list= mrlist
      });
    },
    linkqq(){
         let url = 'http://wpa.qq.com/msgrd?v=3&uin='+this.qq+'&site=qq&menu=yes'
          window.open(url,'_blank')
    }


  },
  //在生命周期updated时，改变并且要在页面重新渲染完成之后
  updated() {
    this.$nextTick(() => {
      this.$refs.record.scrollTop = this.$refs.record.scrollHeight
    })
  },
};
</script>

<style lang="less" scoped>
.service {
  width: 920px;
  margin: 0 auto;
  padding: 0;
  height: 100vh;
  min-height: 750px;

  .coms {
    box-shadow: 0 0 10px #baccf1;
    width: 100%;
  }

  .nav {
    width: 100%;
    background: @head-bg;

    .header {
      color: #fff;
      line-height: 40px;
      font-size: 14px;
      margin: 0 auto;
      padding: 0 20px;

      .logo {
        width: 150px;
      }

      .setMrgin {
        padding: 0 10px;
      }

      a {
        color: #fff;
        text-decoration: none;

        &:hover {
          color: @bule;
        }
      }
    }
  }

  .com {
    width: 100%;
    border: 1px solid #dddddd;

    .l {
      width: 65%;
      height: 670px;
      position: relative;
      border-right: 1px solid #dddddd;

      .top {
        height: 430px;
        border-bottom: 1px solid #dddddd;
        overflow: auto;
        padding-bottom: 20px;

      }
      .ts{
        background: #f3f3f3;
        border-bottom: 1px solid #dddddd;
        line-height: 40px;
        padding: 0 20px;
        font-size: 15px;
        span{
          color: @bule;
          margin-left: 10px;
        }
        .linqq{
          cursor: pointer;
          font-weight: bold;
          text-decoration:underline;
          img{
            height: 25px;
          }
        }
      }

      .botinput {
        width: 100%;
        border: none;
        padding-top:5px;

        ::v-deep .el-textarea__inner {
          border: none;
          font-family:Microsoft YaHei
        }
      }

      .but {
        position: absolute;
        right: 10px;
        bottom: 10px;
        width: 80px;
      }
    }

    .r {
      width: 35%;
      overflow: auto;
      height: 650px;
      padding: 0 20px 20px;
    }

  }
}

</style>
