<template>
  <div class="cjwt">
    <div class="top btw">
    <div class="cjtit">{{nowname}}</div>
    <el-dropdown  @command="handleCommand">
      <el-button type="primary">
        更多分类<i class="el-icon-arrow-down el-icon--right"></i>
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item  v-for="(item,idx) in typelist" :key="idx"  :command="idx" >{{item.typename}}</el-dropdown-item>
      </el-dropdown-menu>

    </el-dropdown>
    </div>
     <el-collapse v-model="activeNames" >
       <el-collapse-item  v-for="(item,idx) in cjwtlist" :key="idx"  :title="item.title" :name="idx" >
         <div v-html="item.answer"></div>
       </el-collapse-item>
     </el-collapse>


  </div>
</template>

<script>
import {getcjwt, getcjwttype} from "@/api/service.js";
export default {
  name: "cjwt.vue",
  props:[],
  data() {
    return {
      activeNames:[],
      typelist: [],
      cjwtlist:[],
      nowname:''
    }
  },
  mounted() {
    this.getcjwttype()
  },
  methods:{
    getcjwttype(){
      getcjwttype().then((data) => {
        this.typelist = data.tdata;
        if(data.tdata.length>0){
          this.changewt(data.tdata[0].typecode,data.tdata[0].typename)
        }
      });
    },
    changewt(val,name) {
      this.nowname = name
      this.activeNames=[]
      let prame={
        typecode:val,
        pageindex:1,
        pagesize:100,
      }
      getcjwt(prame).then((data) => {
        this.cjwtlist = data.rows;
      });
    },
    handleCommand(command) {
      let code = this.typelist[[command]].typecode
      let name = this.typelist[[command]].typename
      this.changewt(code,name)
    },


  }
}
</script>

<style scoped lang="less">
.el-dropdown-link {
  cursor: pointer;
  color: #409EFF;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.cjwt{
  .top{
    margin: 10px 0;
    .cjtit{
      color: #409EFF ;
      font-weight: bold;
      border-bottom: 2px solid #409EFF;
      width: 100px;
      line-height: 40px;
      text-align: center;
      font-size: 15px;

    }
  }
}
::v-deep .el-collapse-item__content{
  background-color: #f0f7ff;
  padding: 5px;
  border-radius: 4px;
  font-size: 14px;
}
::v-deep .el-collapse-item__header.is-active{
  color: #409eff;
  font-size: 14px;
}
::v-deep .el-collapse-item__header{
  line-height: 26px;
  height: auto;
  padding: 8px 0;
}
</style>
