import request from '@/utils/request'
// 常见问题分类
export function getcjwttype(params) {
  return request({
    url: 'web/home/getcjwttype.json',
    method: 'get',
    params
  })
}
// 常见问题答复
export function getcjwt(params) {
  return request({
    url: 'web/home/getcjwt.json',
    method: 'get',
    params
  })
}
